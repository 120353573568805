import React from "react"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { rem, rgba } from "polished"

import { media } from "@sr/common"

import { SanityImg } from "../../../types/index"
import AppLayoutWidth from "../../App/Layout/Width"
import BlockContentInline from "../../BlockContent/Inline"
import ButtonGroup, { ButtonGroupInstance } from "../../Button/Group"
import { BdTitle3 } from "../../Typography/index"

interface SectionCardGridItemSanityImg extends SanityImg {
  alt?: string
}
export interface SectionCardGridProps {
  bgColor?: string
  cards?: {
    bgColor?: string
    buttons?: ButtonGroupInstance[]
    content?: any[]
    header?: string
    img?: SectionCardGridItemSanityImg
    textColor?: string
  }[]
  className?: string
  columns?: number
}

const SectionCardGrid = ({
  bgColor = "var(--color-content-bg-invert)",
  cards,
  className,
  columns = 2,
}: SectionCardGridProps) => {
  return (
    <SectionCardGridStyled
      className={className}
      style={{ backgroundColor: bgColor }}
    >
      {cards && cards.length > 0 ? (
        <ul className="cards">
          {cards.map((card, index) => (
            <li
              className="card"
              key={index}
              style={{
                backgroundColor:
                  card.bgColor || "var(--color-content-bg-invert)",
                color: card.textColor || "var(--color-text-subtract)",
                flex: `0 0 ${100 / columns}%`,
              }}
            >
              {card?.img?.asset && (
                <div className="img">
                  <BdTitle3 className="img__title">{card.header}</BdTitle3>
                  <SanityImage
                    alt={card.img.alt || ""}
                    asset={card.img.asset}
                    className="img__mobile"
                    crop={card.img.crop}
                    hotspot={card.img.hotspot}
                    width={375}
                    height={260}
                  />
                  <SanityImage
                    alt={card.img.alt || ""}
                    asset={card.img.asset}
                    className="img__standard"
                    crop={card.img.crop}
                    hotspot={card.img.hotspot}
                    width={720}
                    height={430}
                  />
                  <div
                    className="img__overlay"
                    style={{
                      backgroundColor:
                        card.bgColor || "var(--color-content-bg-invert)",
                    }}
                  />
                </div>
              )}
              <AppLayoutWidth className="content" constraint="readable">
                <BdTitle3 className="content__title">{card.header}</BdTitle3>
                <div className="content__text">
                  <BlockContentInline blocks={card.content} />
                </div>
                {card.buttons && card.buttons.length > 0 && (
                  <ButtonGroup
                    buttons={card.buttons}
                    className="color-block__buttons"
                  />
                )}
              </AppLayoutWidth>
            </li>
          ))}
        </ul>
      ) : null}
    </SectionCardGridStyled>
  )
}

export default SectionCardGrid

const SectionCardGridStyled = styled.section`
  .cards {
    @media ${media.stUp} {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
    }
  }

  .card {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-bottom: 1px solid
      ${(props: any) => rgba(props.theme.colors.gray.white, 0.1)};

    @media ${media.stUp} {
      border-right: 1px solid
        ${(props: any) => rgba(props.theme.colors.gray.white, 0.1)};
    }

    &:hover .img {
      @media ${media.stUp} {
        opacity: 0.4;
        transform: scale(1.2, 1.2);
      }
    }
  }

  .img {
    position: relative;
    z-index: 1;

    @media ${media.stUp} {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      transform: scale(1, 1);
      transition: opacity 800ms ease-in-out, transform 1200ms ease-in-out;
    }
  }

  .img__title {
    position: absolute;
    left: ${rem(16)};
    bottom: ${rem(16)};
    z-index: 1;

    @media ${media.stUp} {
      display: none;
    }
  }

  .img__mobile {
    @media ${media.stUp} {
      display: none;
    }
  }

  .img__standard {
    display: none;

    @media ${media.stUp} {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      object-fit: cover;
    }
  }

  .img__overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0.45;
  }

  .content {
    padding-top: ${rem(56)};
    padding-bottom: ${rem(56)};

    @media ${media.stUp} {
      padding-top: 8vw;
      padding-bottom: 8vw;
    }
  }

  .content__title {
    display: none;

    @media ${media.stUp} {
      display: block;
      margin-bottom: ${rem(24)};
    }
  }

  .content__text {
    margin-bottom: ${rem(32)};
  }
`

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { BrandingMarkOnDark } from "@sr/gatsby-theme-ui"

// import brandImage from "../../../../../images/brand/secondary-on-dark.svg"
import essexImage from "../../../../../images/brand/essex.svg"
import plusImage from "../../../../../images/plus.svg"

export interface AppFooterBrandProps {
  className?: string
}

const AppFooterBrand = ({ className }: AppFooterBrandProps) => {
  return (
    <AppFooterBrandStyled className={`${className} footer-brand`}>
      <Link to="/" className="footer-brand__link">
        <BrandingMarkOnDark   className="footer-brand__img"/>
        {/* <img
          className="footer-brand__img"
          src={brandImage}
          alt="SmartRent.com logo"
        /> */}
        <img
          className="footer-brand__img__plus"
          src={plusImage}
          alt="Plus image"
        />
        <img className="footer-brand__img" src={essexImage} alt="Essex logo" />
      </Link>
    </AppFooterBrandStyled>
  )
}

export default AppFooterBrand

const AppFooterBrandStyled = styled.div`
  padding: ${rem(16)} 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${media.stUp} {
    width: var(--st-column-default);
  }

  .footer-brand__link {
    gap: ${rem(24)};
    display: flex;

    @media ${media.stUp} {
      flex-direction: row;
      align-items: center;
    }
  }

  .footer-brand__img {
    width: 100%;
    height: ${rem(48)};
  }

  .footer-brand__img__plus {
    width: 100%;
    height: ${rem(32)};
  }
`

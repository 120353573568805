/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Global context provider
import React from "react"
import { App, GlobalStateContextProvider } from "@sr/gatsby-theme-ui"

export const wrapRootElement = ({ element }) => {
  return <GlobalStateContextProvider>{element}</GlobalStateContextProvider>
}

/** Fix for styled-components not being applied at SSR
 * see: https://github.com/gatsbyjs/gatsby/issues/24341
 * and: https://davidmitjana.me/blog/fixing-styled-components-ssr-in-gatsby-build
 */
export const wrapPageElement = ({ element }) => {
  return <App>{element}</App>
}

import React from "react"
import SanityImage from "gatsby-plugin-sanity-image"

import styled from "styled-components"

import { getFlexBoxAlignmentString, media } from "@sr/common"

import { AlignXType, AlignYType, SanityImg } from "../../../types/index"
import MediaBgImg from "../../Media/BgImg"
import MediaBgVideo from "../../Media/BgVideo"
import SectionSplitContentMediaVideo from "./Video"

export type SectionSplitContentMediaImgFitType = "cover" | "contain"
export type SectionSplitContentMediaVideoFitType = "cover" | "contain"

export interface SectionSplitContentMediaProps {
  className?: string
  mediaImgMobileHide?: boolean
  mediaImgStandardHide?: boolean
  mediaImgFit?: SectionSplitContentMediaImgFitType
  sanityMediaImg?: SanityImg
  mediaImgXAlignStandard?: AlignXType
  mediaImgYAlignStandard?: AlignYType
  mediaVideo?: string
  mediaVideoFit?: SectionSplitContentMediaVideoFitType
  mediaVideoMobileHide?: boolean
  sanityMediaVideoPoster?: SanityImg
  mediaVideoStandardHide?: boolean
  mediaVideoXAlign?: AlignXType
  mediaVideoYAlign?: AlignYType
}

const SectionSplitContentMedia = ({
  className,
  mediaImgMobileHide = false,
  mediaImgStandardHide = false,
  mediaImgFit,
  sanityMediaImg,
  mediaImgXAlignStandard = "center",
  mediaImgYAlignStandard = "center",
  mediaVideo,
  mediaVideoFit,
  sanityMediaVideoPoster,
}: SectionSplitContentMediaProps) => {
  const mediaType = mediaVideo ? "video" : "img"

  return (
    <SectionSplitContentMediaStyled
      alignItemsSt={getFlexBoxAlignmentString(mediaImgXAlignStandard)}
      className={`${className} split-content-media`}
      hideImgM={mediaImgMobileHide}
      hideImgSt={mediaImgStandardHide}
      justifyContentSt={getFlexBoxAlignmentString(mediaImgYAlignStandard)}
    >
      {mediaType === "img" && sanityMediaImg && mediaImgFit === "contain" && (
        <SanityImage
          className="split-content-media__img-square"
          alt={sanityMediaImg.alt || ""}
          asset={sanityMediaImg.asset}
          crop={sanityMediaImg.crop}
          hotspot={sanityMediaImg.hotspot}
          width={1500}
        />
      )}
      {mediaType === "img" && sanityMediaImg && mediaImgFit === "cover" && (
        // Mobile fix for cover image display:
        // otherwise, render as background media
        <>
          <SanityImage
            className="split-content-media__img-square split-content-media__img-square--hide-desktop"
            alt={sanityMediaImg.alt || ""}
            asset={sanityMediaImg.asset}
            crop={sanityMediaImg.crop}
            hotspot={sanityMediaImg.hotspot}
            width={1500}
            height={1500}
          />

          <MediaBgImg
            className="split-content-media__bg-img split-content-media__bg-img--hide-mobile"
            bgImg={sanityMediaImg}
          />
        </>
      )}
      {mediaType === "video" &&
        sanityMediaVideoPoster &&
        mediaVideoFit === "cover" && (
          <MediaBgImg
            className="split-content-media__bg-video-poster"
            bgImg={sanityMediaVideoPoster}
          />
        )}
      {mediaType === "video" && mediaVideoFit === "cover" && mediaVideo && (
        <MediaBgVideo
          autoPlay={true}
          className="split-content-media__bg-video"
          loop={true}
          source={mediaVideo}
        />
      )}
      {mediaType === "video" && mediaVideoFit === "contain" && mediaVideo && (
        <SectionSplitContentMediaVideo
          className="split-content-media__video"
          source={mediaVideo}
        />
      )}
    </SectionSplitContentMediaStyled>
  )
}

export default SectionSplitContentMedia

interface MediaSC {
  alignItemsSt: string
  hideImgM: boolean
  hideImgSt: boolean
  justifyContentSt: string
}

const SectionSplitContentMediaStyled = styled.div<MediaSC>`
  position: relative;
  z-index: 1;
  display: ${({ hideImgM }) => (hideImgM ? "none" : "block")};
  line-height: 0;

  @media ${media.stUp} {
    display: ${({ hideImgSt }) => (hideImgSt ? "none" : "flex")};
    flex-direction: column;
    align-items: ${({ alignItemsSt }) => alignItemsSt};
    justify-content: ${({ justifyContentSt }) => justifyContentSt};
  }

  .split-content-media__img-square {
    width: 100%;
  }

  .split-content-media__img-square--hide-desktop {
    @media ${media.stUp} {
      display: none;
    }
  }

  .split-content-media__bg-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .split-content-media__bg-img--hide-mobile {
    display: none;

    @media ${media.stUp} {
      display: block;
    }
  }

  .split-content-media__bg-video-poster {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .split-content-media__bg-video {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .split-content-media__video {
    width: 100%;
  }
`

import React, { ReactNode, useState } from "react"
import { useForm } from "react-hook-form"
import { useCookies } from "react-cookie"
import { useInView } from "react-intersection-observer"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

import { useI18n } from "../GlobalState/LanguageProvider"
import AppLayoutWidth from "../App/Layout/Width"
import Button from "../Button/Button"
import { RegBodyMed, SemiBdTitle4 } from "../Typography/index"

export interface SectionNewsletterCalloutProps {
  btnText?: string | ReactNode
  className?: string
  placeholder?: string
  title?: string | ReactNode
}

const SectionNewsletterCallout = ({
  btnText,
  className,
  placeholder,
  title,
}: SectionNewsletterCalloutProps) => {
  const [success, setSuccess] = useState<boolean>(false)
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  })

  //Does not exist on Localhost
  const [cookies] = useCookies(["hubspotutk"])
  const { t } = useI18n()
  const defaultTitle = t("components.section.newsletterCallout.titleDefault")
  const defaultButtonText = t(
    "components.section.newsletterCallout.btnTextDefault"
  )
  const defaultPlaceholder = t(
    "components.section.newsletterCallout.placeholderDefault"
  )

  const [ref] = useInView({
    threshold: 0,
    rootMargin: "0px 0px 0px",
    triggerOnce: true,
  })

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const context: { pageUri: string; pageName: string; hutk?: string } = {
      pageUri: window.location.href,
      pageName: document.title,
    }
    if (cookies.hubspotutk) {
      context.hutk = cookies.hubspotutk
    }
    const data = JSON.stringify({
      fields: [
        {
          name: "email",
          value: event.target[0].value,
        },
      ],
      context,
    })

    const response = await fetch(
      "https://api.hsforms.com/submissions/v3/integration/submit/20434445/3e590bc0-808a-41f3-b712-84d281a58b66",
      {
        method: "POST",
        headers: {
          "content-Type": "application/json",
        },
        body: data,
      }
    )
    const body = await response.json()
    if (body.status !== "error") {
      setSuccess(true)
    } else {
      let error = body.errors[0].message
        .split("Error in 'fields.email'. ")
        .pop()
      setError("email", { type: "custom", message: error })
    }
  }

  return (
    <SectionNewsletterCalloutStyled
      className={`${className} newsletter`}
      ref={ref}
    >
      <AppLayoutWidth constraint="readableLg">
        <h2 className="newsletter__title">{title || defaultTitle}</h2>
        <AnimatePresence>
          {errors && errors.email && (
            <motion.p
              className="email-validation-message"
              initial={{ y: 40, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 40, opacity: 0 }}
            >
              {errors.email.message}
            </motion.p>
          )}
        </AnimatePresence>
        <AnimatePresence>
          <form onSubmit={(event) => handleSubmit(event)}>
            <input
              className={`newsletter__input ${
                errors && errors.email ? "error" : ""
              }`}
              id="Email"
              placeholder={placeholder || defaultPlaceholder}
              {...register("email", {
                required: "This field is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Please enter a valid email address",
                },
              })}
              type="email"
              onChange={() => clearErrors()}
            />
            <button type="submit">
              <Button as="span">{btnText || defaultButtonText}</Button>
            </button>
          </form>
        </AnimatePresence>
      </AppLayoutWidth>
      <AnimatePresence>
        {success && (
          <motion.div
            className="success"
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100%", opacity: 0 }}
            transition={{ damping: 50, stiffness: 200 }}
          >
            <AppLayoutWidth constraint="readable">
              <SemiBdTitle4>Thank you!</SemiBdTitle4>
              <RegBodyMed>You've been added to our newsletter.</RegBodyMed>
            </AppLayoutWidth>
          </motion.div>
        )}
      </AnimatePresence>
      <form className="u-hidden" id="mktoForm_1119"></form>
    </SectionNewsletterCalloutStyled>
  )
}

export default SectionNewsletterCallout

const SectionNewsletterCalloutStyled = styled.section`
  position: relative;
  padding: ${rem(56)} 0;
  overflow: hidden;
  text-align: center;
  color: var(--color-text-subtract);
  background: var(--color-content-bg-invert);

  @media ${media.stUp} {
    padding: 10vh 0;
  }

  .newsletter__title {
    margin-bottom: ${rem(24)};
    font-size: ${rem(16)};
    line-height: ${rem(24)};
  }

  .email-validation-message {
    color: var(--color-danger);
  }

  .newsletter__input {
    width: 100%;
    margin-bottom: ${rem(32)};
    padding: ${rem(16)} 0;
    text-align: center;
    font-size: ${rem(24)};
    line-height: ${rem(36.5)};
    font-weight: 600;
    color: var(--color-primary);
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    transition: border-color 250ms ease;
    -webkit-appearance: none;

    @media ${media.stUp} {
      font-size: ${rem(40)};
      line-height: ${rem(48)};
    }

    &:focus {
      outline: 0;
      border-bottom-color: var(--color-primary);
    }

    ::placeholder,
    ::-webkit-input-placeholder {
      color: var(--color-text-subtract);
    }

    :-ms-input-placeholder {
      color: var(--color-text-subtract);
    }

    &.error {
      color: var(--color-danger);
      border-bottom-color: var(--color-danger);
    }
  }

  .success {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--color-primary);

    h3 {
      margin-bottom: ${rem(16)};
      font-size: ${rem(32)};
      line-height: ${rem(32 * 1.25)};
      font-weight: 700;
    }
  }
`

// This component shadows the AppHeader component in @sr/gatsby-theme-ui
// packages/gatsby-theme-ui/sr/components/App/Header/index.tsx
// See: https://www.gatsbyjs.com/docs/how-to/plugins-and-themes/shadowing/
import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { media } from "@sr/common"
import { BrandingMark, BrandingSecondary } from "@sr/gatsby-theme-ui"

export const headerLargeBreakpoint = media.stMidUp

import appStore from "../../../../../images/appstore-dark.svg"
import googlePlay from "../../../../../images/googleplay-dark.svg"

const appStoreUrl = "https://apps.apple.com/us/app/smartrent/id1280670682"
const googlePlayUrl =
  "https://play.google.com/store/apps/details?id=com.smartrent.resident"

const AppHeader = () => {
  return (
    <AppHeaderStyled
      role="banner"
      headerLargeBreakpoint={headerLargeBreakpoint}
    >
      <div className="header__brand">
        <a href="https://www.smartrent.com">
          <BrandingMark className="header__brand__mobile-mark" />
          <BrandingSecondary className="header__brand__mark" />
        </a>
      </div>
      <div className="header__applinks">
        <ul className="header__applinks__list">
          <li className="header__applink__item">
            <a href={appStoreUrl} target="_blank" rel="noopener noreferrer">
              <img
                className="header__applink__item__img"
                src={appStore}
                alt="SmartRent.com logo"
              />
            </a>
          </li>
          <li className="header__applink__item">
            <a href={googlePlayUrl} target="_blank" rel="noopener noreferrer">
              <img
                className="header__applink__item__img"
                src={googlePlay}
                alt="SmartRent.com logo"
              />
            </a>
          </li>
        </ul>
      </div>
      <a
        className="nav-supp__support-button"
        href="https://smartrent.com/support/"
      >
        Support
      </a>
      <a
        className="nav-supp__login-button"
        href="https://control.smartrent.com/login"
      >
        Sign In
      </a>
    </AppHeaderStyled>
  )
}

export default AppHeader

interface HeaderSC {
  isMobileVisible: boolean
  headerHeight: number
  headerLargeBreakpoint: string
}

const AppHeaderStyled = styled.header<HeaderSC>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  z-index: 2;
  height: ${rem(64)};
  background: var(--color-content-bg);
  border-bottom: 1px solid var(--color-border-light);

  @media ${({ headerLargeBreakpoint }) => headerLargeBreakpoint} {
    padding: 0 0 0 ${rem(48)};
  }

  .header__brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__brand__mobile-mark {
    @media ${({ headerLargeBreakpoint }) => headerLargeBreakpoint} {
      display: none;
    }
  }

  .header__brand__mark {
    display: none;

    @media ${({ headerLargeBreakpoint }) => headerLargeBreakpoint} {
      display: block;
    }
  }

  .header__applinks {
    margin: ${rem(8)} 0 0 auto;
  }

  .header__applinks__list {
    display: none;

    @media ${media.stUp} {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: ${rem(42)};
    }
  }

  .nav-supp__support-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(96)};
    height: 100%;
    color: var(--color-gray700);

    @media ${({ headerLargeBreakpoint }) => headerLargeBreakpoint} {
      width: 8%;
      font-size: ${rem(12)};
    }
  }

  .nav-supp__login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(96)};
    height: 100%;
    color: var(--color-text-subtract);
    background: var(--color-link);

    @media ${({ headerLargeBreakpoint }) => headerLargeBreakpoint} {
      width: 8%;
      text-transform: uppercase;
      font-weight: 700;
      font-size: ${rem(12)};
    }
  }
`

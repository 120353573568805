import React from "react"

import img from "../../images/brand/secondary-on-dark.svg"

export interface BrandingSecondaryOnDarkProps {
  className?: string
  width?: number
  height?: number
  alt?: string
  loading?: string
}

const BrandingSecondaryOnDark = ({
  className,
  width = 98.3497,
  height = 83.7124,
  alt = "",
}: BrandingSecondaryOnDarkProps) => {
  return (
    <img
      alt={alt}
      className={className}
      src={img}
      width={width}
      height={height}
      loading="lazy"
    />
  )
}

export default BrandingSecondaryOnDark

import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { rem } from "polished"
import { media, getContainerWidth, getContainerHeight } from "@sr/common"
import styled from "styled-components"

//adding all logo images
const dottedCircle =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986436/pages/home-03-16-2023/integrations/dotted-circle_kpdl0v.svg"
const dottedCircleInner =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986436/pages/home-03-16-2023/integrations/dotted-circle-inner_eomjcx.svg"
const cambium =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1693517936/pages/home-03-16-2023/logos/Cambium_Logo_dtw36c.svg"
const kwikset =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986438/pages/home-03-16-2023/logos/Kwikset_tfh7xs.svg"
const ring =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986441/pages/home-03-16-2023/logos/ring_ipddcq.svg"
const resideo =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1692810136/pages/home-03-16-2023/logos/Resideo_Logo_bzf25j.svg"
const anyoneHome =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1715026444/pages/home-03-16-2023/logos/AnyoneHome_Logo_dmex5k.svg"
const ruckus =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1692809972/pages/home-03-16-2023/logos/Ruckus_Logo_f8rsaz.svg"
const engrain =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986440/pages/home-03-16-2023/logos/engrain_fpwfla.svg"
const entrata =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986438/pages/home-03-16-2023/logos/entrata_n0pih9.svg"
const realpage =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986437/pages/home-03-16-2023/logos/realpage_iwxis4.svg"
const yardi =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986439/pages/home-03-16-2023/logos/yardi_n77xdw.svg"
const frame =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986439/pages/home-03-16-2023/logos/frame_jn4jfq.svg"
const funnel =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986439/pages/home-03-16-2023/logos/funnel_ghb0r2.svg"
const resman =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986438/pages/home-03-16-2023/logos/resman_mjyaro.svg"
const yale =
  "https://res.cloudinary.com/smartrent-com/image/upload/q_auto/v1678986437/pages/home-03-16-2023/logos/yale_do9icv.svg"
const primaryOnWhite =
  "https://res.cloudinary.com/smartrent-com/image/upload/v1715013757/logos/brandmark-primary_r63hbd.svg"

export interface IntegrationAnimationProps {
  containerRef: any
}

//We pass the container ref of the parent container to allow for sizing of the animation
function IntegrationAnimation({ containerRef }: IntegrationAnimationProps) {
  //set initial values
  const [innerRadius, setInnerRadius] = useState(125)
  const [outerRadius, setOuterRadius] = useState(215)

  const handleResize = () => {
    //The porportions on the animation are set by the containing elements dimensions, we first get the height and width of the container and set the smaller of the two to be the window size
    const containerWidth = getContainerWidth(containerRef)
    const containerHeight = getContainerHeight(containerRef)
    const smallerWindowSize = Math.min(containerWidth, containerHeight)

    //We set the inner radius to be half way, and the outer radius to be the full width, minus the 72px for the size of the logos
    setInnerRadius(smallerWindowSize * 0.5)
    setOuterRadius(smallerWindowSize - 72)
  }

  //on mount we run a resize to get the properly sized animation, as well as add a resize handler that will adjust the animation size when the window is changed.
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const innerCircleObjects = [
    { image: cambium },
    { image: kwikset },
    { image: ring },
    { image: ruckus },
    { image: anyoneHome },
    { image: yale },
  ]

  const outerCircleObjects = [
    { image: resideo },
    { image: entrata },
    { image: engrain },
    { image: realpage },
    { image: yardi },
    { image: frame },
    { image: funnel },
    { image: resman },
  ]
  // This code allows us to add or remove partner logos and sets them evenly spaced accross the animation for both the outside and inside tracks
  const innerNumObjects = innerCircleObjects.length
  const innerObjectDegrees = 360 / innerNumObjects
  const outerNumObjects = outerCircleObjects.length
  const outerObjectDegrees = 360 / outerNumObjects

  // sets the pace and ease of animation
  const duration = 200
  const ease = "linear"

  return (
    <IntegrationAnimationStyled>
      <div className="integration-animation__container">
        <motion.img
          className="integration-animation__smartrent-center inner-dotted-circle"
          src={dottedCircleInner}
          animate={{
            width: innerRadius,
            height: innerRadius,
          }}
          loading="lazy"
        />
        <motion.img
          className="integration-animation__smartrent-center outer-dotted-circle"
          src={dottedCircle}
          animate={{
            width: outerRadius,
            height: outerRadius,
          }}
          loading="lazy"
        />
        <div className="integration-animation__smartrent-center smartrent-icon">
          <img
            className="integration-animation__brand-mark"
            src={primaryOnWhite}
            width={64}
            height={54}
            loading="lazy"
          />
        </div>
        <motion.div
          className="integration-animation__smartrent-center inner-circle__container"
          animate={{
            rotate: 360,
            transition: {
              duration,
              repeat: Infinity,
              ease,
            },
          }}
        >
          {innerCircleObjects.map((obj, index) => {
            const angle = index * innerObjectDegrees
            const radians = (angle * Math.PI) / 180
            const x = (Math.cos(radians) * innerRadius) / 2
            const y = (Math.sin(radians) * innerRadius) / 2

            return (
              <motion.div
                key={index}
                className="integration-animation__smartrent-center"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
                }}
                animate={{
                  transition: {
                    duration,
                    ease,
                    repeat: Infinity,
                  },
                }}
              >
                <motion.div
                  className="integration-animation__inner-circle"
                  animate={{
                    rotate: -360,

                    transition: {
                      duration,
                      ease,
                      repeat: Infinity,
                    },
                  }}
                >
                  <img
                    src={obj.image}
                    className="integration-animation__inner-circle__logo"
                    width={72}
                    height={72}
                    style={{ width: "auto", height: "auto" }}
                    loading="lazy"
                  />
                </motion.div>
              </motion.div>
            )
          })}
        </motion.div>
        <motion.div
          className="integration-animation__smartrent-center integration-animation__inner-circle__container"
          animate={{
            rotate: -360,
            transition: {
              duration,
              ease,
              repeat: Infinity,
            },
          }}
        >
          {outerCircleObjects.map((obj, index) => {
            //this fancy math is what positions the logos around in a circle
            const angle = index * outerObjectDegrees
            const radians = (angle * Math.PI) / 180
            const x = (Math.cos(radians) * outerRadius) / 2
            const y = (Math.sin(radians) * outerRadius) / 2

            return (
              <motion.div
                key={index}
                className="integration-animation__smartrent-center"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
                }}
                animate={{
                  transition: {
                    duration,
                    ease,
                    repeat: Infinity,
                  },
                }}
              >
                <motion.div
                  className="integration-animation__inner-circle"
                  animate={{
                    rotate: 360,
                    transition: {
                      duration,
                      ease,
                      repeat: Infinity,
                    },
                  }}
                >
                  <img
                    src={obj.image}
                    className="integration-animation__inner-circle__logo"
                    width={72}
                    height={72}
                    style={{ width: "auto", height: "auto" }}
                    loading="lazy"
                  />
                </motion.div>
              </motion.div>
            )
          })}
        </motion.div>
      </div>
    </IntegrationAnimationStyled>
  )
}

export default IntegrationAnimation

const IntegrationAnimationStyled = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;

  @media ${media.stUp} {
    overflow: visible;
  }

  .integration-animation__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .integration-animation__smartrent-center {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  .inner-dotted-circle {
    z-index: 0;

    @media ${media.midUp} {
    }
  }

  .outer-dotted-circle {
    z-index: 0;

    @media ${media.midUp} {
    }
  }

  .smartrent-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${rem(90)};
    width: ${rem(90)};
    box-shadow: 0 ${rem(4)} ${rem(4)} rgba(0, 0, 0, 0.25);

    @media ${media.stUp} {
      height: ${rem(120)};
      width: ${rem(120)};
    }
  }

  .integration-animation__inner-circle {
    height: ${rem(42)};
    width: ${rem(42)};

    border: ${rem(1)} solid #cad0da;
    box-shadow: 0 ${rem(4)} ${rem(4)} rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    position: relative;
    z-index: 1;
    background: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${rem(10)} ${rem(4)};

    @media ${media.midUp} {
      height: ${rem(60)};
      width: ${rem(60)};
    }

    @media ${media.stMidUp} {
      height: ${rem(72)};
      width: ${rem(72)};
    }
  }

  .integration-animation__brand-mark {
    display: block;
    margin-left: ${rem(8)};
  }
  .integration-animation__inner-circle__logo {
    max-height: 98%;
  }
`

import React, { ReactNode } from "react"
import { Reset } from "styled-reset"
import AppCtaBanner from "./CtaBanner/index"
import { default as defaultTheme } from "../../theme/index"
import ThemeProvider from "../ThemeProvider"
import LanguageProvider from "../GlobalState/LanguageProvider"
import GlobalStyle from "../GlobalStyle"
import AppLayout, {
  AppLayoutWidth,
  appHeaderHeight,
  appHeaderMainHeight,
  appHeaderSuppHeight,
  appHeaderHeightMobile,
  layoutPadding,
  layoutWidths,
  layoutZIndexes,
} from "./Layout/index"
import AppFooter from "./Footer/index"
import AppFooterBrand from "./Footer/Brand"

export interface AppProps {
  theme?: any
  /** Provide a language file to be merged
   * with the default package lang file
   */
  lang?: any
  children: ReactNode
}

const App = ({ theme = defaultTheme, lang, children }: AppProps) => {
  console.log(`ENV from page: ${process.env.GATSBY_ENV_TEST}`)

  return (
    <LanguageProvider lang={lang}>
      <ThemeProvider theme={theme}>
        <Reset />
        <GlobalStyle />
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,600;1,600&display=swap');
        `,
          }}
        />
        <style
          dangerouslySetInnerHTML={{
            __html: `
            @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,700;1,700&display=swap');
        `,
          }}
        />
        <AppLayout>{children}</AppLayout>
      </ThemeProvider>
    </LanguageProvider>
  )
}

export {
  App,
  AppCtaBanner,
  AppLayoutWidth,
  AppFooter,
  AppFooterBrand,
  appHeaderHeight,
  appHeaderMainHeight,
  appHeaderSuppHeight,
  appHeaderHeightMobile,
  layoutPadding as appPadding,
  layoutWidths as appWidths,
  layoutZIndexes as appZIndexes,
}

import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import { media } from "@sr/common"

export interface MediaBlockImgProps {
  alt?: string
  className?: string
  height?: number
  float?: "left" | "right" | "none"
  src: string
  width?: number
}

const MediaBlockImg = ({
  alt,
  className,
  height,
  float,
  src,
  width,
}: MediaBlockImgProps) => {
  const floatDirection = float && float !== "none" ? float : null
  return (
    <MediaBlockImgStyle
      className={`block-img ${className || ""}`}
      float={floatDirection}
    >
      <img
        alt={alt}
        className="block-img__img"
        height={height}
        src={src}
        width={width}
      />
    </MediaBlockImgStyle>
  )
}

export default MediaBlockImg

interface ImgSC {
  float: "left" | "right" | null
}

const MediaBlockImgStyle = styled.div<ImgSC>`
  @media ${media.stUp} {
    ${({ float }) =>
      float
        ? `
      float: ${float};
      width: 50%;
      padding-top: ${rem(16)};
      padding-right: ${float === "left" ? rem(24) : "0"};
      padding-bottom: ${rem(16)};
      padding-left: ${float === "right" ? rem(24) : "0"};
    `
        : ``}
  }
`

import React, { ReactNode } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import Button, { ButtonStyle } from "./Button"

export interface ButtonGroupInstance {
  btnColor?: string
  btnStyle?: ButtonStyle
  content: string | ReactNode
  onClick?: () => void
  path?: string
  url?: string
}

export interface ButtonGroupProps {
  align?: string
  buttons: ButtonGroupInstance[]
  className?: string
}

const ButtonGroup = ({
  align = "flex-start",
  buttons,
  className,
}: ButtonGroupProps) => {
  if (!buttons || buttons.length === 0) return null

  const renderButton = (
    color: string | undefined,
    style: ButtonStyle = "default",
    content: string | ReactNode,
    onClick: (() => void) | undefined,
    asButton: boolean
  ) => {
    return (
      <Button
        as={asButton ? "button" : "span"}
        btnColor={color}
        btnStyle={style}
        onClick={onClick}
      >
        {content}
      </Button>
    )
  }
  return (
    <ButtonGroupStyled align={align} className={`${className} button-group`}>
      {buttons.map((button, index) => {
        if (button.path) {
          return (
            <Link
              className="u-text-decoration-none button-group__button"
              key={index}
              to={button.path}
            >
              {renderButton(
                button.btnColor,
                button.btnStyle,
                button.content,
                button.onClick,
                false
              )}
            </Link>
          )
        } else if (button.url) {
          return (
            <a
              className="button-group__button"
              href={button.url}
              key={index}
              rel="noopener noreferrer"
              target="_blank"
            >
              {renderButton(
                button.btnColor,
                button.btnStyle,
                button.content,
                button.onClick,
                true
              )}
            </a>
          )
        } else {
          return (
            <span className="button-group__button" key={index}>
              {renderButton(
                button.btnColor,
                button.btnStyle,
                button.content,
                button.onClick,
                true
              )}
            </span>
          )
        }
      })}
    </ButtonGroupStyled>
  )
}

export default ButtonGroup

interface ButtonGroupSC {
  align: string
}

const ButtonGroupStyled = styled.div<ButtonGroupSC>`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};

  .button-group__button {
    margin-right: ${rem(16)};

    &:last-child {
      margin-right: 0;
    }
  }
`

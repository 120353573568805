// This component shadows the AppFooter component in @sr/gatsby-theme-ui
// packages/gatsby-theme-ui/sr/components/App/Footer/index.tsx
// See: https://www.gatsbyjs.com/docs/how-to/plugins-and-themes/shadowing/
import React from "react"

const AppFooter = () => {
  return (
<></>
  )
}

export default AppFooter


import { createGlobalStyle } from "styled-components"
import { rem, rgba } from "polished"
import "typeface-open-sans"

import theme from "../theme/index"

const GlobalStyle = createGlobalStyle`
  :root {
    /* brand colors */
    --color-primary: var(--color-primary-on-light);
    --color-primary-on-light: ${theme.colors.brand.primaryOnLight};
    --color-primary-on-dark: ${theme.colors.brand.primaryOnDark};
    --color-secondary: ${theme.colors.brand.secondary};
    --color-nuetral050: ${theme.colors.brand.nuetral050};
    --color-nuetral100: ${theme.colors.brand.nuetral100};
    --color-nuetral200: ${theme.colors.brand.nuetral200};
   

    /* grays */
    --color-white: ${theme.colors.gray.white};
    --color-gray050: ${theme.colors.gray.gray050};
    --color-gray075: ${theme.colors.gray.gray075};
    --color-gray100: ${theme.colors.gray.gray100};
    --color-gray200: ${theme.colors.gray.gray200};
    --color-gray300: ${theme.colors.gray.gray300};
    --color-gray400: ${theme.colors.gray.gray400};
    --color-gray500: ${theme.colors.gray.gray500};
    --color-gray600: ${theme.colors.gray.gray600};
    --color-gray700: ${theme.colors.gray.gray700};
    --color-gray800: ${theme.colors.gray.gray800};
    --color-gray900: ${theme.colors.gray.gray900};
    --color-gray950: ${theme.colors.gray.gray950};
    --color-black: ${theme.colors.gray.black};
    
    /* symbolic colors */
    --color-knowledge: ${theme.colors.symbolic.knowledge};
    --color-success: ${theme.colors.symbolic.success};
    --color-danger: ${theme.colors.symbolic.danger};
    --color-warning: ${theme.colors.symbolic.warning};

    /* typography colors */
    --color-text: var(--color-gray800);
    --color-text-subtract: var(--color-white);
    --color-link: var(--color-primary);
    --color-link-hover: var(--color-secondary);
    --color-text-disabled: var(--color-gray400);
    --color-text-placeholder: var(--color-gray300);

    /* element colors */
    --color-content-bg: var(--color-white);
    --color-interactive-bg: var(--color-gray050);
    --color-content-bg-invert: var(--color-gray900);
    --color-border-light: var(--color-gray200);

    /* shadows */
    --shadow: ${theme.shadows.shadow};
    --shadow-bottom: ${theme.shadows.shadowBottom};
    --shadow-hard: ${theme.shadows.shadowHard};
    --shadow-hard-left: ${theme.shadows.shadowHardLeft};
    --shadow-light: ${theme.shadows.shadowLight};
    --shadow-top: ${theme.shadows.shadowTop};
    --shadow-inset: ${theme.shadows.shadowInset};

    /* fonts */
    --secondary-font-family:  "Rubik", sans-serif;
    --secondary-font-semibold: font-weight: 600;
    --secondary-font-bold: font-weight: 700;

    /* stylistic */
    --rounded: ${rem(4)};
    --rounded-lg: ${rem(8)};

    /* buttons */
    --button-bg: var(--color-black);
    --button-text-color: var(--color-white);
    --button-secondary-bg: var(--color-nuetral100);
    --button-secondary-text-color: var(--color-black);
  }

  html,
  body {
    font-size: 1rem;
    color: ${theme.colors.gray.gray800};
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: ${rem(24)};
    background: var(--color-content-bg);
    box-sizing: border-box;
  }

  html *,
  html *::before,
  html *::after,
  body *,
  body *::before,
  body *::after {
    box-sizing: inherit;
  }

  sup,
  sub {
      position: relative;
      top: -.4em;
      font-size: 60%;
      vertical-align: baseline;
  }

  sub {
      top: .4em;
  }

  input {
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  }

  /* this disables the autofill background color https://css-tricks.com/almanac/selectors/a/autofill/ */
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 40rem var(--color-content-bg) inset;
    box-shadow: 0 0 0px 40rem var(--color-content-bg) inset;
  }

  button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
    line-height: normal;
    font-size: inherit;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  button:disabled {
    cursor: default;
  }

  a {
    color: var(--color-link);
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--color-link-hover);
      text-decoration: underline;
    }
  }

  .disabled {
    pointer-events: none !important;
    color: var(--color-gray300) !important;
  }

  *:focus {
    outline-color: ${rgba(theme.colors.brand.primary, 0.6)};
  }

  .onfocus:focus {
    outline: 0;
  }

  img {
    max-width: 100%;
  }

  .u-hidden {
    display: none !important;
  }

  .u-hidden-visually {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
  }

  .u-font-secondary-semibold {
    font-family: var(--secondary-font-family) !important;
    font-optical-sizing: auto;
    font-weight: var(--secondary-font-semibold);
    font-style: normal;
  }

  .u-font-secondary-bold {
    font-family: var(--secondary-font-family) !important;
    font-optical-sizing: auto;
    font-weight: var(--secondary-font-bold);
    font-style: normal;
  }

  .u-text-decoration-none:hover {
    text-decoration: none !important;
  }

  .u-text-bold {
    font-weight: 600 !important;
  }

  .u-text-italic {
    font-style: italic !important;
  }

  .u-text-color {
    color: var(--color-text) !important;
  }

  .u-rounded {
    border-radius: var(--rounded) !important;
  }

  .u-rounded-lg {
    border-radius: var(--rounded-lg) !important;
  }
`

export default GlobalStyle
